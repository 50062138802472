import { useContext, createContext, useState, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import moment from "moment"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import { Grid } from "@planningcenter/doxy-web"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useQuery } from "@tanstack/react-query"
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button"
import { useDebounce } from "source/shared/hooks/useDebounce"

EpisodeFilterPanel.propTypes = {
  channelId: PropTypes.string.isRequired,
}

export function EpisodeFilterPanel({ channelId }) {
  return (
    <Grid columns={1} gap={2}>
      <SelectFilters channelId={channelId} />
    </Grid>
  )
}

const FilterContext = createContext()

SelectFilters.propTypes = {
  channelId: PropTypes.string.isRequired,
}

function SelectFilters({ channelId }) {
  const filterTypes = ["series", "speaker"]
  const { series, isLoading: seriesLoading } =
    useSeriesWithPublishedEpisodes(channelId)
  const { speakers, isLoading: speakersLoading } =
    useSpeakersforChannel(channelId)
  const loadingStatuses = { series: seriesLoading, speaker: speakersLoading }
  const options = {
    series: [
      { id: "", name: "All series" },
      ...series.map((s) => ({
        id: s.id,
        name: s.attributes.title,
        start: s.attributes.started_at,
        end: s.attributes.ended_at,
      })),
    ],
    speaker: [
      { id: "", name: "All speakers" },
      ...speakers.map((s) => ({
        id: s.id,
        name: s.attributes.formatted_name,
        type: s.attributes.speaker_type,
      })),
    ],
  }

  const filterContextValues = { options, loadingStatuses }
  return (
    <FilterContext.Provider value={filterContextValues}>
      <FilterGrid>
        {filterTypes.map(
          (filterType) =>
            options[filterType].length > 0 && (
              <FilterSingleSelect key={filterType} filterType={filterType} />
            ),
        )}
      </FilterGrid>
    </FilterContext.Provider>
  )
}

FilterGrid.propTypes = {
  children: PropTypes.node,
}

function FilterGrid({ children }) {
  return (
    <Grid columns={[1, { xs: 2, md: 3, lg: 4 }]} gap={2}>
      {children}
    </Grid>
  )
}

function useTitle(filterType, selectedItemId) {
  const { options, loadingStatuses } = useContext(FilterContext)
  if (loadingStatuses[filterType]) return "Loading..."

  const selectedItem = options[filterType].find(
    (option) => option.id === selectedItemId,
  )

  return selectedItem?.name || "Record Not Available"
}

MenuTitle.propTypes = {
  filterType: PropTypes.string,
  selectedItemId: PropTypes.string,
}

function MenuTitle({ filterType, selectedItemId }) {
  const title = useTitle(filterType, selectedItemId)

  return <MenuButton className="select truncate ta-l">{title}</MenuButton>
}

function formatSeriesDates(startDate, endDate) {
  if (!startDate || !endDate) return null

  const format = (date) => moment(date).format("MMMM YYYY")

  return `${format(startDate)} - ${format(endDate)}`
}

FilterSingleSelect.propTypes = {
  filterType: PropTypes.string,
}

function FilterSingleSelect({ filterType }) {
  const { options } = useContext(FilterContext)
  const menuOptions = options[filterType]
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedItemId = searchParams.get(filterType) || ""
  const [seriesSearchQuery, setSeriesSearchQuery] = useState("")
  const debouncedSeriesSearchQuery = useDebounce(seriesSearchQuery, 300)
  const searchInputRef = useRef(null)

  const onChange = (option) => {
    setSearchParams(
      (prev) => {
        if (option.id !== "") {
          prev.set(filterType, option.id)
          if (filterType === "speaker") {
            prev.set("speaker_type", option.type)
          }
        } else {
          prev.delete(filterType)
          if (filterType === "speaker") {
            prev.delete("speaker_type", option.type)
          }
        }
        return prev
      },
      { replace: true },
    )
  }

  const filteredMenuOptions = menuOptions.filter((option) =>
    option.name
      .toLowerCase()
      .includes(debouncedSeriesSearchQuery.toLowerCase()),
  )

  useEffect(() => {
    if (searchInputRef.current) {
      const input = searchInputRef.current
      const cursorPosition = input.selectionStart
      input.focus()
      input.setSelectionRange(cursorPosition, cursorPosition)
    }
  }, [debouncedSeriesSearchQuery, filteredMenuOptions])

  const handleKeyDown = (e) => {
    const allowedKeys = ["ArrowUp", "ArrowDown"]
    if (!allowedKeys.includes(e.key)) {
      e.stopPropagation()
    }
  }

  return (
    <Menu>
      <MenuTitle
        filterType={filterType}
        selectedItemId={selectedItemId}
      ></MenuTitle>
      <MenuList css={styles.scrollableMenuList}>
        {filterType === "series" && (
          <input
            type="text"
            placeholder="Search series"
            value={seriesSearchQuery}
            onChange={(e) => setSeriesSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            css={styles.searchBar}
            ref={searchInputRef}
          />
        )}
        {(filterType === "series" ? filteredMenuOptions : menuOptions).map(
          (option) => (
            <MenuItem
              key={`${filterType}-${option.id}`}
              onSelect={() => onChange(option)}
              css={styles.menuItemRow}
            >
              {option.name}
              {filterType === "series" && (
                <div css={styles.seriesDate}>
                  {formatSeriesDates(option.start, option.end)}
                </div>
              )}
            </MenuItem>
          ),
        )}
      </MenuList>
    </Menu>
  )
}

function useSeriesWithPublishedEpisodes(channelId) {
  const { data, isLoading } = useQuery({
    queryKey: [
      "channels",
      channelId,
      "series",
      "published,with_published_episodes",
    ],
    queryFn: ({ queryKey }) => {
      const [_, channelId] = queryKey
      return sessionApiClient
        .get(`/publishing/v2/channels/${channelId}/series`, {
          filter: "published,with_published_episodes",
          per_page: 1000,
        })
        .catch((json) => json)
    },
    select: (response) => response.data,
  })
  return { series: data || [], isLoading }
}

function useSpeakersforChannel(channelId) {
  const { data, isLoading } = useQuery({
    queryKey: ["channels", channelId, "speakers"],
    queryFn: ({ queryKey }) => {
      const [_, channelId] = queryKey
      return sessionApiClient
        .get(`/publishing/v2/channels/${channelId}/speakers`, {
          per_page: 1000,
        })
        .catch((json) => json)
    },
    select: (response) => response.data,
  })
  return { speakers: data || [], isLoading }
}

const styles = {
  seriesDate: css`
    color: #737373;
    font-size: 12px;
  `,

  scrollableMenuList: css`
    max-height: 456px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    width: 228px;
    padding: 16px 0px 8px 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #dee3ea;
    background: #fff;
    box-shadow: 0px 11px 20px 0px rgba(75, 76, 78, 0.25);
  `,

  menuItemRow: css`
    display: flex;
    width: 226px;
    padding: 7px 6px 6px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    background: var(--fill-neutral-10---white, #fff);
  `,

  searchBar: css`
    display: flex;
    width: 204px;
    padding: 6x 8px;
    box-sizing: border-box;
    margin-bottom: 8px;
    border: 1px solid #dee3ea;
    border-radius: 4px;
  `,
}
