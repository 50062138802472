import { useState, useEffect, useContext } from "react"
import { reservationStepPropTypes } from "source/registrations/propTypes"
import { useParams } from "react-router-dom"
import { find as _find } from "lodash"
import { isStringBlank } from "source/registrations/utils/helpers"
import { ReservationContext } from "source/registrations/reservations/ReservationContext"
import { patchPaymentIntent } from "source/registrations/api/payments"
import { Loading } from "source/shared/components"
import { useEvent } from "source/registrations/hooks/useEvent"
import SectionLayout from "source/registrations/reservations/SectionLayout"
import StripeWrapper from "source/registrations/StripeWrapper"
import StripeForm from "source/registrations/StripeForm"
import AttendeesBreakdown from "./AttendeesBreakdown"
import ReviewPaymentDiscountForm from "./ReviewPaymentDiscountForm"
import ReviewPaymentMethods from "./ReviewPaymentMethods"
import ReviewPaymentType from "./ReviewPaymentType"
import PaymentProcessingProgressBar from "../PaymentProcessingProgressBar"
import classNames from "classnames"
import { useOrganizationFlag } from "source/registrations/hooks/useOrganization"

const ReviewPayment = ({
  attendees,
  addOns,
  organization,
  reservation,
  isProcessing,
  isAddOnsEnabled,
  onIsProcessing,
  completeRegistrationId,
  onReservationSave,
  className,
  error: reservationError,
  onPaymentComplete,
  step,
}) => {
  const { eventId } = useParams()

  const {
    data: {
      currency,
      allowCreditCardPayments,
      allowOfflinePayments,
      allowAchPayments,
      discounts,
    },
  } = useEvent(eventId)

  const { stripe } = useContext(ReservationContext)

  const savedPaymentsEnabled = useOrganizationFlag("savedPaymentMethods")

  const {
    paymentIntegration: { isConnected, isTestMode },
  } = organization

  const [error, setError] = useState(reservationError)
  const [showDiscounts, setShowDiscounts] = useState(false)
  const [amount, setAmount] = useState(0)
  const [paymentType, setPaymentType] = useState("full")
  const [paymentMethod, setPaymentMethod] = useState("cashCheck")
  const [discountError, setDiscountError] = useState(null)
  const [discountCode, setDiscountCode] = useState("")
  const [canMakePayment, setCanMakePayment] = useState(!isProcessing)
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false)
  const [paymentIntentError, setPaymentIntentError] = useState(null)
  const [paymentIntent, setPaymentIntent] = useState(null)
  const [isCaptureProcessing, setIsCaptureProcessing] = useState(false)
  const [reducedOtherAmount, setReducedOtherAmount] = useState(null)

  const hasTotalDue = reservation.totalDue > 0

  const allowCreditCard =
    hasTotalDue && (allowCreditCardPayments || allowAchPayments)

  const useCreditCardForm =
    allowCreditCard && paymentMethod === "creditCard" && paymentType !== "later"

  const usePaymentMethods = allowOfflinePayments && allowCreditCard

  const usePaymentTypes =
    hasTotalDue &&
    paymentMethod === "creditCard" &&
    reservation.minimumDue >= 0 &&
    reservation.minimumDue !== reservation.totalDue

  useEffect(() => {
    setError(reservationError)
  }, [reservationError])

  useEffect(() => {
    if (!reservation?.paymentIntent?.amount) return

    setPaymentIntent(reservation.paymentIntent)
  }, [reservation.paymentIntent])

  useEffect(() => {
    setPaymentMethod(allowCreditCard ? "creditCard" : "cashCheck")
  }, [allowCreditCard])

  useEffect(() => {
    setAmount(reservation.totalDue)
  }, [reservation.totalDue])

  useEffect(() => {
    if (isPaymentProcessing || paymentIntent?.amount !== amount) {
      setCanMakePayment(false)
    } else {
      setCanMakePayment(true)
    }
  }, [isPaymentProcessing, paymentIntent?.amount, amount])

  // Debugging purposes only
  // https://app.bugsnag.com/planning-center/church-center-web/errors/633f56cca5b98d0008f1b3ae?filters[event.since]=30d&filters[error.status]=open&filters[error.assigned_to]=me
  // "We could not retrieve data from the specified Element. Please make sure the Element you are attempting to use is still mounted."
  useEffect(() => {
    if (
      isConnected &&
      useCreditCardForm &&
      (!stripe || !paymentIntent?.clientSecret)
    ) {
      return () => {
        console.error(
          `something went wrong!\n
            Stripe: ${JSON.stringify(stripe)} \n
            PaymentIntent: ${JSON.stringify(paymentIntent)} \n
            isConnected: ${isConnected} \n
            useCreditCardForm: ${useCreditCardForm}
          `,
        )
      }
    }
  }, [stripe, paymentIntent, isConnected, useCreditCardForm])

  const updatePaymentIntent = async (newAmount) => {
    if (isStringBlank(newAmount)) return

    setIsPaymentProcessing(true)

    const response = await patchPaymentIntent(
      reservation.eventId,
      reservation.id,
      {
        data: { attributes: { amount: newAmount } },
        fields: { PaymentIntent: "amount,amount_formatted" },
      },
    )

    if (response.errors) {
      setPaymentIntentError(response.errors[0]?.detail)
    } else {
      setPaymentIntentError(null)
      setPaymentIntent({
        ...paymentIntent,
        ...response.data,
      })
    }

    setIsPaymentProcessing(false)
  }

  const handleOtherAmountChange = async (value) => {
    let error = null
    const otherAmount = Number(value) * 100

    if (!value) {
      error = "Other amount can not be blank"
    } else if (otherAmount < 50) {
      error = `Other amount must be at least ${currency}0.50`
    } else if (
      otherAmount < reservation.minimumDue ||
      otherAmount > reservation.totalDue
    ) {
      error = "Other amount must be between minimum and full amount"
    }

    if (error) {
      setError(error)
      setCanMakePayment(false)
    } else {
      setError(null)
      await updatePaymentIntent(otherAmount)
      setAmount(otherAmount)
      setCanMakePayment(true)
    }
  }

  const handlePaymentTypeChange = async (value) => {
    setReducedOtherAmount(null)
    setError(null)

    if (value === "other") setCanMakePayment(false)
    let newAmount = null

    if (value === "full") {
      newAmount = reservation.totalDue
    } else if (value === "minimum") {
      newAmount = reservation.minimumDue
    }

    if ((value === "full" || value === "minimum") && newAmount < 50) {
      setError(`Amount must be at least ${currency}0.50`)
      setCanMakePayment(false)
      setPaymentType(value)
      return
    }

    await updatePaymentIntent(newAmount)

    if (newAmount) setAmount(newAmount)
    setError(null)
    setPaymentType(value)
  }

  const handleDiscountRemove = async (discountSelection) => {
    setReducedOtherAmount(null)
    onIsProcessing(true)
    setCanMakePayment(false)

    const response = await onReservationSave(
      {
        data: { attributes: {} },
        included: [
          {
            type: "DiscountSelection",
            attributes: { id: discountSelection.id, _destroy: true },
          },
        ],
      },
      step.name,
    )

    if (paymentType === "full" && response.data.totalDue !== amount) {
      await updatePaymentIntent(response.data.totalDue)
      setAmount(response.data.totalDue)
    } else {
      await updatePaymentIntent(amount)
      setAmount(amount)
    }
    onIsProcessing(false)
    setCanMakePayment(true)
  }

  const handleDiscountSave = async () => {
    onIsProcessing(true)
    setCanMakePayment(false)
    setDiscountError(null)

    const response = await onReservationSave(
      {
        data: { attributes: {} },
        included: [
          { type: "DiscountSelection", attributes: { code: discountCode } },
        ],
      },
      step.name,
    )

    if (!response.error) {
      setShowDiscounts(false)
    }

    if (paymentType === "full" && response.data.totalDue !== amount) {
      await updatePaymentIntent(response.data.totalDue)
      setAmount(response.data.totalDue)
    } else if (paymentType !== "full" && response.data.totalDue < amount) {
      setReducedOtherAmount(response.data.totalDue / 100)
      await updatePaymentIntent(response.data.totalDue)
      setAmount(response.data.totalDue)
      setReducedOtherAmount(null)
    } else {
      await updatePaymentIntent(amount)
      setAmount(amount)
    }
    onIsProcessing(false)
    setCanMakePayment(true)
  }

  const handleSave = async (stripeResponse = null) => {
    onIsProcessing(true)

    let attributes = {
      method: paymentMethod,
      type: paymentType,
    }

    if (paymentMethod === "creditCard" && paymentType !== "later") {
      attributes = {
        ...attributes,
        paymentIntentId: stripeResponse?.id,
        amount: stripeResponse?.amount,
        status: stripeResponse?.status,
      }
    }

    await onReservationSave({
      data: {
        attributes: {
          send_confirmation: true,
        },
      },
      included: [{ type: "Payment", attributes }],
    })

    return onIsProcessing(false)
  }

  const existingDiscountCodeSelection =
    _find(reservation.discountSelections, {
      kind: "code",
    }) || null

  const canApplyDiscount = !isProcessing && (!allowCreditCard || canMakePayment)

  const restrictedTo = discounts
    .filter((d) => d.kind === "code")
    .map((d) => d.restrictedTo)
    .flat()

  const showApplyDiscount = restrictedTo.some((r) =>
    attendees.map((a) => a.attendeeTypeId).includes(r),
  )

  const paymentFormOverlayStyles = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }

  return (
    <div>
      <SectionLayout
        heading={hasTotalDue ? "Review and pay" : "Review"}
        className={className}
        step={step}
        expiresAt={reservation.expiresAt}
        isProcessing={isProcessing}
      >
        {step.isCurrent && (
          <>
            <AttendeesBreakdown
              attendees={attendees}
              addOns={addOns}
              isAddOnsEnabled={isAddOnsEnabled}
              hasTotalDue={hasTotalDue}
              totalDiscountString={reservation.totalDiscountString}
            />

            {hasTotalDue && (
              <div className="d-f ai-c jc-sb mb-3 mt-3">
                <strong>Total</strong>
                <strong>{reservation.totalDueString}</strong>
              </div>
            )}

            {showApplyDiscount && hasTotalDue && (
              <div className="mt-2 mb-1 d-f jc-fe">
                {existingDiscountCodeSelection ? (
                  <button
                    disabled={!canApplyDiscount}
                    className={`secondary-btn minor-btn btn destroy-btn`}
                    onClick={() =>
                      handleDiscountRemove(existingDiscountCodeSelection)
                    }
                  >
                    Remove code discount
                  </button>
                ) : (
                  <button
                    disabled={!canApplyDiscount}
                    className="secondary-btn minor-btn btn"
                    onClick={() => setShowDiscounts(!showDiscounts)}
                  >
                    Apply discount
                  </button>
                )}
              </div>
            )}

            {hasTotalDue && showDiscounts && (
              <ReviewPaymentDiscountForm
                error={discountError}
                discountCode={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                onSubmit={handleDiscountSave}
                isProcessing={isProcessing}
              />
            )}

            {usePaymentMethods && (
              <ReviewPaymentMethods
                onChange={setPaymentMethod}
                paymentMethod={paymentMethod}
              />
            )}

            {usePaymentTypes && (
              <ReviewPaymentType
                onChange={handlePaymentTypeChange}
                onOtherAmountChange={handleOtherAmountChange}
                paymentType={paymentType}
                totalDueString={reservation.totalDueString}
                minimumDue={reservation.minimumDue}
                minimumDueString={reservation.minimumDueString}
                isPaymentUpdating={isPaymentProcessing}
                onCanMakePayment={setCanMakePayment}
                currencySymbol={currency}
                otherAmount={reducedOtherAmount}
              />
            )}

            <StripeWrapper
              stripe={stripe}
              paymentIntent={paymentIntent}
              error={paymentIntentError || error}
            >
              {isConnected && useCreditCardForm && (
                <>
                  {!stripe || !paymentIntent?.clientSecret ? (
                    <Loading />
                  ) : (
                    <div
                      className={classNames("p-r", {
                        "mt-3": !savedPaymentsEnabled,
                      })}
                    >
                      {isCaptureProcessing && isProcessing && (
                        <div css={paymentFormOverlayStyles}>
                          <PaymentProcessingProgressBar
                            completeRegistrationId={completeRegistrationId}
                            onPaymentComplete={onPaymentComplete}
                          />
                        </div>
                      )}
                      <StripeForm
                        responsiblePerson={reservation.responsiblePerson}
                        useStripeWebhooks={reservation.useStripeWebhooks}
                        paymentIntent={paymentIntent}
                        onPaymentSave={handleSave}
                        onIsProcessing={onIsProcessing}
                        onIsCaptureProcessing={setIsCaptureProcessing}
                        amount={amount}
                        onError={setError}
                        isTestMode={isTestMode}
                        isLoading={!stripe || !paymentIntent?.clientSecret}
                      />
                    </div>
                  )}
                </>
              )}
            </StripeWrapper>

            <AttendeesBreakdown attendees={attendees} forWaitlist />
          </>
        )}
        <div className="ta-c my-4">
          {useCreditCardForm && step.isCurrent && (
            <button
              className="btn"
              disabled={!canMakePayment || isPaymentProcessing || isProcessing}
              form="paymentForm"
            >
              {`Register and pay ${
                amount
                  ? `${currency}${parseFloat(amount / 100).toFixed(2)}`
                  : ""
              }`}
            </button>
          )}

          {!useCreditCardForm && step.isCurrent && (
            <button
              className="btn"
              disabled={isProcessing}
              onClick={handleSave}
            >
              {isProcessing ? "Saving..." : "Register"}
            </button>
          )}
        </div>
      </SectionLayout>
    </div>
  )
}

ReviewPayment.propTypes = reservationStepPropTypes

export default ReviewPayment
