import { useParams, useLocation } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import { Library } from "source/publishing/sermons/Channel"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import { Error404 } from "source/shared/components/Error404"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
import NativeHeader from "./shared/NativeHeader"
import { EpisodeFilterPanel } from "./shared/EpisodeFilterPanel"

export function EpisodeList() {
  const { channelId } = useParams()
  const location = useLocation()
  const response = useApiRead(`/publishing/v2/channels/${channelId}/`)
  const episodeFiltersEnabled = useFlipperFeatureEnabled(
    "ROLLOUT_episode_filters",
  )

  useNotifyNativeLoaded()

  const params = new URLSearchParams(location.search)
  const filters = {
    series: params.get("series") || "",
    speaker: params.get("speaker") || "",
    speaker_type: params.get("speaker_type") || "",
  }

  const queryParams = {
    "where[channel_id]": channelId,
    filter: "published_to_library",
    order: "-published_to_library_at",
  }

  if (filters.series) {
    queryParams["where[series_id]"] = filters.series
  }

  if (filters.speaker) {
    queryParams["where[speaker_id]"] = filters.speaker
  }

  if (filters.speaker_type) {
    queryParams["where[speaker_type]"] = filters.speaker_type
  }

  if (response.errors?.[0]?.status === "404") return <Error404 />

  const channel = response.data

  return (
    <div className="d-f fd-c g-2">
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/channels/${channelId}`}
          pageText={channel.attributes.name}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="View all" isActive />
      </BreadcrumbNavigation>
      <NativeHeader
        title={channel.attributes.name}
        rightType={"share"}
        shareUrl={`${channel.attributes.church_center_url}/episodes`}
      />
      {episodeFiltersEnabled && <EpisodeFilterPanel channelId={channelId} />}
      <Library
        key={`${filters.series}-${filters.speaker}`}
        queryParams={queryParams}
      />
    </div>
  )
}
