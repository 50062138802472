import { Navigate, Route, Routes } from "react-router-dom"
import { usePublishingFeature } from "source/publishing"
import { WideLayout, SermonEpisodeLayout } from "source/Layout"
import { Error404 } from "source/shared/components/Error404"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
import { ChannelList } from "./ChannelList"
import { SeriesList } from "./SeriesList"
import { EpisodeList } from "./EpisodeList"
import { Series } from "./Series"
import { SermonNotes } from "./SermonNotes"
import { Channel } from "./Channel"
import { Episode } from "./Episode"
import { LatestEpisode } from "./LatestEpisode"
import { LatestNotes } from "./LatestNotes"

export function SermonChannels() {
  const enabled = usePublishingFeature("sermons")

  if (!enabled) return <Navigate to="/" replace />

  return (
    <WideLayout>
      <Routes>
        <Route path="/" element={<ChannelList />} />
        <Route path="/:channelId/series/:seriesId" element={<Series />} />
        <Route path="/:channelId/series" element={<SeriesList />} />
        <Route path="/:channelId/episodes" element={<EpisodeList />} />
        <Route path="/:channelId/latest-episodes" element={<LatestEpisode />} />
        <Route path="/:channelId/latest-notes" element={<LatestNotes />} />
        <Route path="/:channelId" element={<Channel />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </WideLayout>
  )
}

export function SermonEpisodes() {
  const enabled = usePublishingFeature("sermons")
  const sermonNotesEnabled = useFlipperFeatureEnabled("ROLLOUT_sermon_notes")

  if (!enabled) return <Navigate to="/" replace />

  return (
    <SermonEpisodeLayout>
      <div
        tabIndex="-1"
        className="d-f fd-c d-b@md"
        style={{ outline: "none" }}
      >
        <Routes>
          <Route path="/:episodeId" element={<Episode />} />
          {sermonNotesEnabled ? (
            <Route path="/:episodeId/notes" element={<SermonNotes />} />
          ) : null}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
    </SermonEpisodeLayout>
  )
}
